
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import StreetView from '@/views/v2/views/components/GoogleStreetView.vue';

export default defineComponent({
  props: {
    coordinates: {
      default: () => {
        return { lat: Number, lng: Number };
      },
    },
    loading: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: false },
  },
  components: { Modal, StreetView },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  emits: ['submit', 'update:modelValue'],
  methods: {
    initializeStreetView() {
      (this.$refs.streetview as any).initialStreetView();
    },
  },
});
