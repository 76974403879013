
import { defineComponent } from 'vue';
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
import HistoryItem from '@/views/v2/views/pages/ViewTenantReview/HistoryItem.vue'
import StreetViewModal from '@/views/v2/views/pages/ViewTenantReview/StreetViewModal.vue'
import _ from 'lodash'

export default defineComponent({
  name: 'rental-review-list-section',
  emits: ['selectRentalHistory'],
  components: {
    HistoryItem,
    StreetViewModal
  },
  props: {
    currentTenantReview: {
      type: Array,
      default: () => undefined,
    },
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data(){
    return {
      loadingList: true,
      selectedCoordinates: {} as any,
      showingStreetView: false
    }
  },
  watch: {
    selectedCoordinates: {
      handler(){
        this.$nextTick(() => {
          (this.$refs.streetviewmodal as any).initializeStreetView()
        })
      },
      deep: true
    }
  },
  computed: {
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
  },
  methods: {
    isCurrent(review) {
      return this.$route.params.ref == review.reference_no;
    },
    selectRentalHistory(referenceNo) {
      this.$emit('selectRentalHistory', referenceNo);
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    toDecimal(number) {
      return numberFormat(number, 1);
    },
    getAddress(review) {
      return `${review?.address_line}, ${review?.city}, ${review?.post_code}, ${review?.state}`;
    },
    getDates(review) {
      return `${this.dateFormat(review.move_in_date)} - ${this.dateFormat(
        review.move_out_date
      )}`;
    },
    hasCoordinates(review){
      return parseFloat(review.latitude) != 0 && parseFloat(review.longitude) != 0
    },
    expandStreetView(coordinates: any){
      this.selectedCoordinates = coordinates;
      this.showingStreetView = true;

      console.log('COORDINATES', coordinates);
    },
  }
});
