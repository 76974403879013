
import { defineComponent } from 'vue';
import router from '@/router';
import timeUtil from '@/utils/time';
import maskUtil from '@/utils/mask';
import { Tenant } from '@/models/TenantModel';
import { numberFormat } from '@/utils/text';
import Avatar from '@/components/avatars/Avatar.vue';
import { removeSpaces, capitalize, acronym } from '@/utils/text';

export default defineComponent({
  components: { Avatar },
  props: {
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    tenant: {
      type: Object,
      default: () => {
        return {} as unknown as Tenant;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
  },
  data: () => ({
    currentTenant: {} as unknown as Tenant,
  }),
  methods: {
    requestReview() {
      router.push({
        name: 'request-tenant-report',
      });
    },
    removeSpaces(value) {
      return removeSpaces(value);
    },
  },
  computed: {
    rate() {
      return numberFormat(this.averageRating, 1);
    },
    mobileMasking() {
      return maskUtil.mobile.aus;
    },
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    fullName() {
      const fname = capitalize(`${this.currentTenant.first_name}`);
      const lname = capitalize(`${this.currentTenant.last_name}`);

      if (fname == '' && lname == '') {
        return 'Not Specified';
      }

      return `${fname} ${lname}`;
    },
    tenantAvatarSrc() {
      if (!this.currentTenant?.avatar_src) return;

      return this.currentTenant?.avatar_src;
    },
    tenantAcronym() {
      if (
        this.currentTenant.first_name == '' &&
        this.currentTenant.last_name == ''
      )
        return acronym(this.currentTenant.email);

      return acronym(
        `${this.currentTenant.first_name} ${this.currentTenant.last_name}`
      );
    },
    tenantRole() {
      // return this.currentTenant.roles[0];
      return 'Tenant';
    },
    reviewsCount() {
      return `${(this.tenantReviews as any).length} reviews`;
    },
  },
  watch: {
    tenant(values) {
      this.currentTenant = {
        ...(this.$props.tenant as Tenant),
        ...{ date_of_birth: timeUtil.dateFormat(values.date_of_birth) },
      };
    },
  },
  mounted() {
    this.currentTenant = {
      ...(this.$props.tenant as Tenant),
      ...{
        date_of_birth: timeUtil.dateFormat((this.tenant as any).date_of_birth),
      },
    };
  },
});
