
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    methods: {
      requestMyReference() {
        this.$router.push('/tenant/request');
      },
      
    },
  });
  