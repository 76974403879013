import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b31aa0f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 view-tenant-review-history" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryItem = _resolveComponent("HistoryItem")!
  const _component_StreetViewModal = _resolveComponent("StreetViewModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenantReviews, (review, key) => {
      return (_openBlock(), _createBlock(_component_HistoryItem, {
        onExpandStreetView: _ctx.expandStreetView,
        "current-tenant-review": _ctx.currentTenantReview,
        review: review,
        key: key,
        onViewReport: ($event: any) => (_ctx.selectRentalHistory(review.reference_no))
      }, null, 8, ["onExpandStreetView", "current-tenant-review", "review", "onViewReport"]))
    }), 128)),
    _createVNode(_component_StreetViewModal, {
      ref: "streetviewmodal",
      modelValue: _ctx.showingStreetView,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showingStreetView) = $event)),
      coordinates: _ctx.selectedCoordinates
    }, null, 8, ["modelValue", "coordinates"])
  ]))
}