
import { defineComponent, onMounted } from 'vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';

import RentalReviewHistory from '@/components/sections/tenant-reviews/RentalReviewHistory.vue';
import RentalReviewDetails from '@/components/sections/tenant-reviews/RentalReviewDetails.vue';
import TenantProfileDetails from '@/components/sections/tenants/TenantReviewProfile.vue';

import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import {
  Actions as TActions,
  Getters as TGetter,
} from '@/store/enums/TenantEnums';
import { TenantReview } from '@/models/TenantReviewModel';
import { Tenant } from '@/models/TenantModel';
import { numberFormat } from '@/utils/text';

import Navigator from '@/views/v2/views/layout/Navigator.vue';
import Header from '@/views/v2/views/pages/ViewTenantReview/Header.vue';
import History from '@/views/v2/views/pages/ViewTenantReview/History.vue';
const initialValues = {
  id: '',
  tenant_id: '',
  reference_no: '',
} as {} as unknown as TenantReview;
import useBreakpoints from 'vue-next-breakpoints';
import NoReview from '@/views/v2/views/pages/ViewTenantReview/NoReview.vue'
import PDFGenerator from '@/views/v2/views/components/PDFGenerator.vue';
import LedgerReport from '@/views/v2/views/pages/Report/Ledger.vue';

export default defineComponent({
  name: 'view-tenant',
  props: {
    customClass: {
      type: String,
      default: 'mx-auto',
    },
  },
  data: () => ({
    currentTenantReview: initialValues,
    currentRefNo: '' as string,
    routeRefNo: '' as string | string[],
    tenantId: '' as string | string[],
    loading: false,
    reviewDetailLoading: false,
    averageRating: '0',
    tab: 'report',
    breakpoints: {},
  }),
  async mounted() {
    this.loading = true;
    this.reviewDetailLoading = true;
    this.breakpoints = useBreakpoints({
      mobile: 800, // max-width: 600px
      desktop: [801], // min-width: 601px
    });

    setCurrentPageTitle('Get Tenant Reference');

    this.setTenantId();

    await this.loadTenantReviews();

    await this.setCurrentTenantReview();

    this.computeAverageRating();
  },
  components: {
    Navigator,
    Header,
    History,
    NoReview,
    PDFGenerator,
    LedgerReport
  },

  computed: {
    ...mapGetters({
      tenantReview: Getters.GET_TENANT_REVIEW,
      tenant: AuthGetters.GET_AUTH_USER,
      tenantReviews: Getters.GET_ALL_TENANT_REVIEWS,
    }),

    tenantRecord() {
      return this.tenant;
    },
    hideList() {
      // if (this.breakpoints) return;

      return this.$route.params.ref && (this.breakpoints as any)?.mobile?.matches;
    },
    defaultReportData(){
      return {
        first_name: this.tenantRecord.first_name,
        last_name: this.tenantRecord.last_name,
        mobile_number: this.tenantRecord.mobile_number,
        email: this.tenantRecord.email
      }
    }
  },
  methods: {
    ...mapActions({
      fetchTenantReviews: Actions.FETCH_ALL_TENANT_REVIEWS,
    }),

    setTenantId() {
      this.tenantId = this.tenant.id;
    },

    async setCurrentTenantReview() {
      if (
        (typeof this.currentRefNo !== 'undefined' &&
          this.currentRefNo === '') ||
        !this.$route.params.ref
      ) {
        this.currentRefNo = this.tenantReviews[0]?.reference_no;

        if (!(this.breakpoints as any).mobile.matches) {
          this.$router.push({
            name: 'tenant-profile-view-ref',
            params: { ref: this.currentRefNo },
          });
        }
      }

      // fetch tenant reviews by refno
      this.currentTenantReview = this.tenantReviews.filter((tenantReview) => {
        if (tenantReview.reference_no == this.currentRefNo) {
          return tenantReview;
        }
      })[0];

      this.loading = false;
      this.reviewDetailLoading = false;
    },

    async loadTenantReviews() {
      // fetch tenant reviews by tenant id
      await this.fetchTenantReviews(this.tenantId);

      this.loading = false;
      this.reviewDetailLoading = false;
    },

    handleSelectRentalHistory(values) {
      // this.loading = true;
      // this.reviewDetailLoading = true;

      // setTimeout(() => {
      //   this.currentRefNo = values;
      //   this.setCurrentTenantReview();
      // }, 500);
      this.$router.push({
        name: 'tenant-profile-view-ref',
        params: { ref: values },
      });
    },
    computeAverageRating() {
      if (
        typeof this.tenantReviews !== 'undefined' &&
        this.tenantReviews.length > 0
      ) {
        let sumRating = 0;
        this.tenantReviews.map((review: TenantReview) => {
          sumRating = sumRating + parseFloat(review.average_rating);
        }, 0);

        const average = `${sumRating / this.tenantReviews.length}`;
        this.averageRating = numberFormat(average);
      }
    },
    requestReview() {
      this.$router.push({
        name: 'request-tenant-report',
      });
    },
    generatePDF(){
      (this.$refs.pdfReport as any).generatePDf();
    },
  },
  setup() {
    onMounted(() => {
      const key = 'AIzaSyAGX7VifDi6W0AlZhM4Wkv31D2cm08s7wA'//process.env.VUE_APP_GOOGLEMAPS_KEY;
      const googleMapScript2 = document.createElement('SCRIPT');

      googleMapScript2.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${ key }&callback=initialize&v=weekly&libraries=places`
      );
      
      googleMapScript2.setAttribute('defer', '');
      googleMapScript2.setAttribute('async', '');
      document.head.appendChild(googleMapScript2);
    });
  },

  //   // const redirectProfile = (id: string | number) => {
  //   //   router.push(`/tenant-report/search/${id}`);
  //   // };
  //   // const dateFormat = (date, format = 'Y-MM-DD' as string) => {
  //   //   return moment(date).format(format);
  //   // };
  //   // return {
  //   //   redirectProfile,
  //   // };
  // },
});
