
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'street-view-image',
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
  },
  emits: ['view'],
  methods: {
    expandStreetView() {
      this.$emit('view');
    },
  },
});
