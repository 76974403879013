
import { defineComponent } from 'vue';
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
import StreetViewImage from '@/views/v2/views/pages/ViewTenantReview/StreetViewImage.vue';
import _ from 'lodash';
import { TenantReview } from '@/models/TenantReviewModel';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  name: 'rental-review-list-section',
  emits: ['selectRentalHistory', 'expandStreetView', 'viewReport'],
  components: {
    StreetViewImage,
  },
  props: {
    currentTenantReview: {
      type: Array,
      default: () => undefined,
    },
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    review: {
      type: Object,
      default: () => {
        return {} as unknown as TenantReview;
      },
    },
  },
  data() {
    return {
      loadingList: true,
      streetViewImage: '',
    };
  },
  computed: {
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    breakpoints(){
      return useBreakpoints({
        mobile: 600, // max-width: 600px
        desktop: [601], // min-width: 601px
      });
    }
  },
  methods: {
    // ...mapActions({
    //   fetchStreetView: Actions.FETCH_STREETVIEW_IMAGE,
    // }),
    isCurrent(review) {
      return this.$route.params.ref == review.reference_no;
    },
    selectRentalHistory(referenceNo) {
      this.$emit('selectRentalHistory', referenceNo);
    },
    dateFormat(date, format='aus') {
      return timeUtil.dateFormat(date, format);
    },
    toDecimal(number) {
      return numberFormat(number, 1);
    },
    getAddress(review) {
      return `${review?.address_line}, ${review?.city}, ${review?.post_code}, ${review?.state}`;
    },
    getDates(review) {
      if ((this.breakpoints as any).mobile.matches) {
        return `${this.dateFormat(review.move_in_date, 'short')} - ${this.dateFormat(
          review.move_out_date, 'short'
        )}`;
      }

      return `${this.dateFormat(review.move_in_date)} - ${this.dateFormat(
        review.move_out_date
      )}`;
    },
    hasCoordinates(review) {
      return (
        parseFloat(review.latitude) != 0 && parseFloat(review.longitude) != 0
      );
    },
    // async getStreeViewImage() {
    //   await this.fetchStreetView({
    //     reviewRefNo: (this.review as any)?.reference_no,
    //   })
    //     .then(async (logoRawImageData) => {
    //       const imageData = image.rawImageToDataUrl(logoRawImageData, 'jpg');
    //       this.streetViewImage = imageData;
    //     })
    //     .catch(() => {
    //       this.streetViewImage = require('@/views/v2/assets/images/property-default-image.png');
    //     });
    // },
    viewStreetView() {
      this.$emit('expandStreetView', {
        lat: parseFloat((this.review as any)?.latitude),
        lng: parseFloat((this.review as any)?.longitude),
      });
    },
    viewReport() {
      this.$emit('viewReport');
    },
    getRate(rate) {
      return parseFloat(this.toDecimal(rate)) > 0 ? this.toDecimal(rate) : 'Not Reviewed';
    },
    hasRate(rate){
      return parseFloat(this.toDecimal(rate)) > 0;
    },
  },
  // created() {
  //   if (this.review) {
  //     this.getStreeViewImage();
  //   }
  // },
});
