
import { ref, onBeforeMount, onMounted } from 'vue';

const __default__ = {
  name: 'GMapStreetView',
  props: {
    coordinates: { lat: Number, lng: Number },
    query: { default: null },
    height: { default: '100vh' },
    width: { default: '100%' },
  },
  setup(props) {
    const mapPanoRef = ref(null);
    const initialStreetView = () => {
      
      const coordinates = (props as any).coordinates;

      console.log('COORDINATES', coordinates);

      const pano = new (window as any).google.maps.StreetViewPanorama(
          mapPanoRef.value,
          {
              position: coordinates,
              // streetViewControl: false,
              // disableDefaultUI: true,
              pov: {
                  heading: 34,
                  pitch: 10,
              },
          }
      );
    }
    // (window as any).initialize = initialStreetView;

    return {
      mapPanoRef,
      initialStreetView
    };
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "e02aa974": (_ctx.width),
  "539bc3a7": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__